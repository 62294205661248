import React, { Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'routes';
import debounce from 'lodash/debounce';
import Text from 'components/ui/Text';
import KaleidoscopeContext from 'components/ui/Kaleidoscope/Context';

import styles from './ProjectList.scss';
import grid from 'styles/grid.scss';

const ProjectItem = ({ project, className }) => {
    const subHeadline = (
        <Fragment>
            <span className={styles.client}>{project.client}</span>
            <span className={styles.separator}>{' / '}</span>
            <span className={styles.type}>{project.type}</span>
        </Fragment>
    );

    return (
        <Link passHref route={`/work/${project.slug}`}>
            <Text as="a" theme="listHeadline" className={className}>
                {project.name}
                <Text as="span" theme="descriptor" className={styles.sub}>
                    <span className={styles.subInner}>{subHeadline}</span>
                </Text>
            </Text>
        </Link>
    );
};
ProjectItem.propTypes = {
    project: PropTypes.object.isRequired,
    className: PropTypes.string,
};

const ProjectList = ({ projects, activeProjectIndex, transitionState }) => {
    const isEntering = transitionState === 'entering';
    const isEntered = transitionState === 'entered';
    const isExiting = transitionState === 'exiting';
    const isExited = transitionState === 'exited';

    const ctx = useContext(KaleidoscopeContext);
    const showWorkImageDebounced = debounce(ctx.showWorkImage, 300, {
        leading: true,
    });
    const handleMouseOver = url => () => showWorkImageDebounced(url);
    useEffect(() => {
        if (isExiting || isExited) {
            ctx.fadeTo(1);
        } else if (isEntering || isEntered) {
            ctx.fadeTo(0.7);
        }
    }, [transitionState]);

    const wrapperClass = cx(styles.projectListWrapper, {
        [styles.projectListWrapperExited]: isExited,
        [styles.projectListWrapperExiting]: isExiting,
        [styles.projectListWrapperEntering]: isEntering,
    });

    return (
        <div className={wrapperClass}>
            <ul className={cx(styles.projectList, grid.container)}>
                {projects.map((project, i) => (
                    <li
                        key={i}
                        className={styles.projectItem}
                        onMouseOver={handleMouseOver(project.image.file.url)}
                    >
                        <ProjectItem
                            as={null}
                            project={project}
                            isActive={activeProjectIndex === i}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};
ProjectList.propTypes = {
    projects: PropTypes.array.isRequired,
    activeProjectIndex: PropTypes.number,
    transitionState: PropTypes.string,
};

export default ProjectList;
