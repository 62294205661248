import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Head from 'next/head';
import { connect } from 'react-redux';
import { Transition, TransitionGroup } from 'react-transition-group';

import ViewToggle from 'components/ui/StickyHeader/Header/ViewToggle';
import ProjectCarousel from 'components/ui/ProjectCarousel';
import ProjectList from 'components/ui/ProjectList';
import { StickyHeaderTunnel } from 'components/ui/StickyHeader';
import { ScrollContainer } from 'components/ui/AppLayout';

import { fetchWorkDetailPageIfNeeded, setActiveProjectIndex } from 'store';
import { workViews } from 'utils/constants';

import styles from './Work.scss';

class Work extends React.Component {
    static getInitialProps = ({ store }) => {
        const state = store.getState();
        return {
            contentTypeId: state.pages['/work'].contentTypeId,
            activeProjectIndex: state.activeProjectIndex,
        };
    };

    static propTypes = {
        // Global pageProps from App.js getInitialProps
        work: PropTypes.shape({
            projects: PropTypes.array.isRequired,
        }).isRequired,
        // Component-specific props from getInitialProps
        contentTypeId: PropTypes.string.isRequired,
        activeProjectIndex: PropTypes.number.isRequired,
        // Props passed directly from App.js render()
        hasTransition: PropTypes.bool.isRequired,
        fontsLoaded: PropTypes.bool.isRequired,
        // Mapped state and dispatchers
        workView: PropTypes.string.isRequired,
        setActiveProjectIndex: PropTypes.func.isRequired,
        fetchWorkDetailPageIfNeeded: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.postMount = true;
        this.prefetchWorkDetailPage(this.props.activeProjectIndex);
    }

    handleProjectCarouselSnap = projectIndex => {
        this.props.setActiveProjectIndex(projectIndex);
        this.prefetchWorkDetailPage(projectIndex);
    };

    prefetchWorkDetailPage(projectIndex) {
        const project = this.props.work.projects[projectIndex];
        if (project) {
            this.props.fetchWorkDetailPageIfNeeded(project.slug);
        }
    }

    render() {
        const {
            work,
            workView: view,
            activeProjectIndex,
            fontsLoaded,
        } = this.props;
        const { projects } = work;
        const { carousel } = workViews;
        const hasTransition = this.postMount || this.props.hasTransition;
        const WorkIndexView = view === carousel ? ProjectCarousel : ProjectList;
        const className = cx(styles.work, {
            [styles.carousel]: view === carousel,
        });
        return (
            <ScrollContainer className={styles.workContainer}>
                <Head>
                    <title>Work | HAUS</title>
                </Head>
                <StickyHeaderTunnel>
                    <ViewToggle />
                </StickyHeaderTunnel>
                <div className={className}>
                    <TransitionGroup
                        component="section"
                        className={styles.workTransition}
                    >
                        <Transition timeout={800} key={view}>
                            {state => (
                                <WorkIndexView
                                    projects={projects}
                                    transitionState={state}
                                    hasTransition={hasTransition}
                                    fontsLoaded={fontsLoaded}
                                    activeProjectIndex={activeProjectIndex}
                                    onSnap={this.handleProjectCarouselSnap}
                                />
                            )}
                        </Transition>
                    </TransitionGroup>
                </div>
            </ScrollContainer>
        );
    }
}

function mapStateToProps(state) {
    return {
        workView: state.workPage.view,
        activeProjectIndex: state.activeProjectIndex,
    };
}
const mapDispatchToProps = {
    setActiveProjectIndex,
    fetchWorkDetailPageIfNeeded,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Work);
