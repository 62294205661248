import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import memoize from 'lodash/memoize';
import { updateWorkPageView } from 'store';
import { workViews } from 'utils/constants';

import FancyLink from 'components/ui/FancyLink';
import Text from 'components/ui/Text';

import styles from './ViewToggle.scss';

class ViewToggle extends React.Component {
    static propTypes = {
        activeView: PropTypes.string.isRequired,
        updateWorkPageView: PropTypes.func.isRequired,
    };

    toggleView = memoize(view => () => {
        return this.props.updateWorkPageView(view);
    });

    render() {
        const { activeView } = this.props;
        const carouselActive = activeView === workViews.carousel;
        const gridActive = activeView === workViews.list;
        return (
            <div className={styles.viewToggle}>
                <FancyLink
                    selected={carouselActive}
                    onClick={this.toggleView(workViews.carousel)}
                    className={cx(styles.option, {
                        [styles.active]: carouselActive,
                    })}
                >
                    {workViews.carousel}
                </FancyLink>
                <Text theme="eyebrows" as="span" className={styles.separator}>
                    /
                </Text>
                <FancyLink
                    selected={gridActive}
                    onClick={this.toggleView(workViews.list)}
                    className={cx(styles.option, {
                        [styles.active]: gridActive,
                    })}
                >
                    {workViews.list}
                </FancyLink>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        activeView: state.workPage.view,
    };
}

const mapDispatchToProps = {
    updateWorkPageView,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewToggle);
